import React, { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive';

import { HEADER_INLINE_SEARCH_BAR_KEY } from '~/containers/Header/constants';
import { mediaQueryPreset } from '~/containers/shared/constants';
import NavLogo from '~/components/Header/NavLogo';
import NavSearch from '~/components/Header/NavSearch';
import BurgerIcon from '~/components/Header/ControlRow/BurgerIcon';
import AccountIcon from '~/components/Header/ControlRow/AccountIcon';
import CartIcon from '~/components/Header/ControlRow/CartIcon';
import './ControlMobile.scss';

function Monogram() {
  return (
    <a
      className="c-monogram u-inline-block u-t-primary u-t-nolined is-still"
      href="/"
    >
      <span
        className="c-monogram__mark ic-bef ic-logo-monogram ic-solid"
        aria-hidden
      />
    </a>
  );
}

export default function NavControlMobile() {
  const isMobile = useMediaQuery(mediaQueryPreset.mobile);

  const [cookies] = useCookies([HEADER_INLINE_SEARCH_BAR_KEY]);

  const hasMobileInlineSearch = useMemo(
    () => isMobile && !!cookies?.[HEADER_INLINE_SEARCH_BAR_KEY],
    [cookies, isMobile]
  );

  if (hasMobileInlineSearch)
    return (
      <div className="c-nvMenuMobl">
        <div className="c-nvMenuMobl__seg is-side">
          <BurgerIcon />
          <Monogram />
        </div>
        <div className="c-nvMenuMobl__seg is-fill">
          <NavSearch />
        </div>
        <div className="c-nvMenuMobl__seg is-side">
          <AccountIcon />
          <CartIcon />
        </div>
      </div>
    );

  return (
    <div className="c-nvMenuMobl">
      <div className="c-nvMenuMobl__seg is-left">
        <BurgerIcon />
      </div>
      <div className="c-nvMenuMobl__seg is-mid">
        <NavLogo />
      </div>
      <div className="c-nvMenuMobl__seg is-right">
        <div className="c-nvMenuMobl__actions">
          <AccountIcon />
          <CartIcon />
        </div>
      </div>
    </div>
  );
}
