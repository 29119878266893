import { atom } from 'recoil';
import { atomKeys } from '~/containers/Header/constants';

const navigation = atom({
  key: atomKeys.NAVIGATION,
  default: []
});

const scrollData = atom({
  key: atomKeys.SCROLL_DATA,
  default: {}
});

const recalcOnFontsLoad = atom({
  key: atomKeys.RECALC_ON_FONTS_LOAD,
  default: false
});

const burgerOpen = atom({
  key: atomKeys.BURGER_OPEN,
  default: false
});

const burgerBrokenImages = atom({
  key: atomKeys.BURGER_BROKEN_IMAGES,
  default: []
});

const listFilterOpen = atom({
  key: atomKeys.LIST_FILTER_OPEN,
  default: false
});

const rootHover = atom({
  key: atomKeys.ROOT_HOVER,
  default: null
});

const dropdownOpen = atom({
  key: atomKeys.DROPDOWN_OPEN,
  default: false
});

const searchFocus = atom({
  key: atomKeys.SEARCH_FOCUS,
  default: false
});

const cartSessions = atom({
  key: atomKeys.CART_SESSIONS,
  default: {}
});

const navSearchQuery = atom({
  key: atomKeys.NAV_SEARCH_QUERY,
  default: ''
});

const klevuNavSearchResults = atom({
  key: atomKeys.KLEVU_NAV_SEARCH_RESULTS,
  default: {}
});

const klevuPopularTerms = atom({
  key: atomKeys.KLEVU_POPULAR_TERMS,
  default: []
});

const searchSuggestions = atom({
  key: atomKeys.SEARCH_SUGGESTIONS,
  default: []
});

export default {
  rootHover,
  navigation,
  scrollData,
  burgerOpen,
  burgerBrokenImages,
  recalcOnFontsLoad,
  listFilterOpen,
  searchFocus,
  dropdownOpen,
  cartSessions,
  navSearchQuery,
  klevuNavSearchResults,
  klevuPopularTerms,
  searchSuggestions
};
