import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';

import atoms from '~/containers/shared/states/atoms';
import NavLogo from '~/components/Header/NavLogo';
import NavBaseOptionsLinks from '~/components/Header/NavBaseOptions/Links';
import NavSearch from '~/components/Header/NavSearch';
import BurgerIcon from '~/components/Header/ControlRow/BurgerIcon';
import AccountIcon from '~/components/Header/ControlRow/AccountIcon';
import CartIcon from '~/components/Header/ControlRow/CartIcon';
import NavIcon from '~/components/Header/NavIcon';
import useHotlineHours, { Tooltip } from '~/hooks/shared/useHotlineHours';
import { CONTACT_US_PATH } from '~/utils/pagePaths';
import './Desktop.scss';

export default function NavControlDesktop() {
  const leftBox = useRef();
  const linksBox = useRef();
  const rightBox = useRef();

  const [gapWidth, setGapWidth] = useState(0);
  const viewportWidth = useRecoilValue(atoms.viewportWidth);

  const { t } = useTranslation();
  const { days = '', dura = '' } = useHotlineHours();

  const boxWidth = useCallback((ref, defaultWidth = 0) => {
    const { width = 0 } = isEmpty(ref?.current)
      ? { width: defaultWidth }
      : ref.current.getBoundingClientRect();
    return width;
  }, []);

  useEffect(() => {
    const leftW = boxWidth(leftBox, 90);
    const linksW = boxWidth(linksBox, 244);
    const rightW = boxWidth(rightBox, 123);

    setGapWidth(leftW + linksW - rightW);
  }, [boxWidth, viewportWidth]);

  return (
    <div className="c-nvMenuDesk">
      <div ref={leftBox} className="c-nvMenuDesk__seg is-left">
        <div className="c-nvMenuDesk__burger u-inline-block">
          <BurgerIcon />
        </div>
        <NavLogo />
      </div>
      <div ref={linksBox} className="c-nvMenuDesk__seg is-links">
        <NavBaseOptionsLinks />
      </div>
      <div className="c-nvMenuDesk__seg is-mid">
        <div className="c-nvMenuDesk__search">
          <NavSearch />
        </div>
      </div>
      <div className="c-nvMenuDesk__seg is-gap" style={{ width: gapWidth }} />
      <div ref={rightBox} className="c-nvMenuDesk__seg is-right">
        <Tooltip days={days} dura={dura} mailto={t('common:contact.email')}>
          <a
            className="c-nvMenuDesk__contact u-inline-block u-t-nolined is-still"
            href={CONTACT_US_PATH}
          >
            <NavIcon iconClass="ic-socl-contact" />
          </a>
        </Tooltip>
        <AccountIcon expandable />
        <CartIcon />
      </div>
    </div>
  );
}
