import { useState, useEffect } from 'react';
import { useScrollData as rawScrollData } from 'scroll-data-hook';
import { useRecoilState } from 'recoil';
import { isFinite, isEqual } from 'lodash';

import headerAtoms from '~/containers/Header/states/atoms';

export default function useScrollData(ready) {
  const [scrollData, setScrollData] = useRecoilState(headerAtoms.scrollData);

  const [scrLastDir, setScrLastDir] = useState('down');
  const [scrLastPos, setScrLastPos] = useState(0);
  const {
    scrolling,
    direction: { y: dirY = null },
    position: { y: posY = null }
  } = rawScrollData();

  useEffect(() => {
    if (ready && scrolling && !!dirY) setScrLastDir(dirY);
    if (ready && scrolling && isFinite(posY)) setScrLastPos(posY);
  }, [dirY, posY, ready, scrolling]);

  useEffect(() => {
    setScrollData((prev) => {
      const next = { direction: scrLastDir, position: scrLastPos };
      return isEqual(next, prev) ? prev : next;
    });
  }, [scrLastDir, scrLastPos, setScrollData]);

  return scrollData;
}
