/* eslint-disable react/jsx-props-no-spreading,react/no-array-index-key */
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import {
  KlevuFetch,
  search as klevuSearch,
  KlevuTypeOfSearch,
  KlevuTypeOfRecord,
  KlevuEvents
} from '@klevu/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { useCookies } from 'react-cookie';
import Downshift from 'downshift';
import classNames from 'classnames';
import queryString from 'query-string-for-all';
import { isFunction, isEmpty, findIndex, noop } from 'lodash';

import {
  HEADER_INLINE_SEARCH_BAR_KEY,
  keyboardKeys
} from '~/containers/Header/constants';
import { RESULT_PAGE_URL } from '~/containers/SearchResult/constants';
import { mediaQueryPreset } from '~/containers/shared/constants';
import atoms from '~/containers/Header/states/atoms';
import selectors from '~/containers/Header/states/selectors';
import PlaceholderTicker from '~/components/shared/PlaceholderTicker';
import useDebounce from '~/hooks/shared/useDebounce';
import useSearchSuggestions from '~/hooks/Header/useSearchSuggestions';
import { localStorageHelper } from '~/utils/storageHelper';
import { humanListCount, defaultForUndefinedOrNull } from '~/utils/helper';
import { KLEVU_ABTEST_KEY } from '~/utils/Search/klevuHelper';
import searchHrefFor from '~/utils/Header/helper';
import { ga4Events } from '~/utils/analytics/gtm';
import { trackSearchSuggestAction } from '~/containers/SearchResult/analytics';
import './NavSearch.scss';

const DELAY_SEARCH_INTENT = 2000;
const DELAY_INPUT_ONCHANGE = 200;

export default function NavSearch() {
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);

  const overlayRef = useRef();
  const [boxHeight, setBoxheight] = useState(null);
  const [isHover, setIsHover] = useState(null);
  const [movingList, setMovingList] = useState([]);
  const [showPrefix, setShowPrefix] = useState(false);

  const intentRef = useRef();
  const intentDataRef = useRef();
  const [searchQuery, setSearchQuery] = useRecoilState(atoms.navSearchQuery);
  const setSearchFocus = useSetRecoilState(
    selectors.searchFocusDropDownOpen(!isDesktop)
  );
  const searchFocus = useRecoilValue(atoms.searchFocus);
  const setKlevuNavSearchResults = useSetRecoilState(
    atoms.klevuNavSearchResults
  );

  const suggestionList = useSearchSuggestions();

  const displayTerms = useMemo(
    () => suggestionList.map((s) => s?.name),
    [suggestionList]
  );

  const cleanQuery = useMemo(() => searchQuery.trim(), [searchQuery]);

  const itemToString = useCallback(
    (item) => (item?.id ? '' : cleanQuery),
    [cleanQuery]
  );

  const stateReducer = useCallback((state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        return {
          ...changes,
          isOpen: state.isOpen
        };
      default:
        return changes;
    }
  }, []);

  const highlighted = useCallback(
    ({ name = '' }) => {
      const safeTerm = cleanQuery.replace(/[^\w\s]/gi, '');
      const parts = name.split(new RegExp(`(${safeTerm})`, 'gi'));

      return parts.map((part, index) => (
        <span
          key={index}
          className={classNames({
            'u-t-bold': safeTerm.toLowerCase() === part.toLowerCase()
          })}
        >
          {part}
        </span>
      ));
    },
    [cleanQuery]
  );

  const abTestInfo = useCallback(
    () =>
      defaultForUndefinedOrNull(
        localStorageHelper.getItem(KLEVU_ABTEST_KEY)?.[0],
        {}
      ),
    []
  );

  const search = useCallback(
    async (query) => {
      if (isEmpty(query)) return;
      const queryID = 'search_autocomplete';

      const res = await KlevuFetch(
        klevuSearch(query, {
          id: queryID,
          limit: 10,
          fields: ['id', 'name', 'url', 'category'],
          typeOfRecords: [KlevuTypeOfRecord.Category],
          typeOfSearch: KlevuTypeOfSearch.WildcardAnd,
          excludeIds: [{ key: 'itemGroupId', value: 'EXCLUDE' }]
        })
      );

      const searchResult = res.queriesById(queryID);
      if (!searchResult) return;

      const {
        getSearchClickSendEvent,
        meta: { totalResultsFound, searchedTerm, typeOfSearch } = {}
      } = searchResult;

      intentDataRef.current = {
        term: searchedTerm,
        totalResults: totalResultsFound,
        typeOfSearch,
        abTestId: abTestInfo()?.abTestId,
        abTestVariantId: abTestInfo()?.abTestVariantId
      };
      if (isFunction(getSearchClickSendEvent)) getSearchClickSendEvent();
      setKlevuNavSearchResults(searchResult);
    },
    [abTestInfo, setKlevuNavSearchResults]
  );

  const lazyQuery = useDebounce(() => search(cleanQuery), DELAY_INPUT_ONCHANGE);

  const trackIntent = useCallback((cb = noop) => {
    if (!isEmpty(intentDataRef.current)) {
      trackSearchSuggestAction(ga4Events?.view_search_suggest, {
        query: intentDataRef.current?.term,
        total: defaultForUndefinedOrNull(intentDataRef.current?.totalResults, 0)
      });
      KlevuEvents.search(intentDataRef.current);
      intentDataRef.current = null;
    }
    cb();
  }, []);

  const inputOnChange = useCallback(
    (ev) => {
      const query = defaultForUndefinedOrNull(ev.target.value, '');
      setSearchQuery(query);
      lazyQuery();
    },
    [lazyQuery, setSearchQuery]
  );

  const inputOnHover = useCallback((hover) => () => setIsHover(hover), []);

  const inputOnKeyDown = useCallback(
    (ev) => {
      if (ev.key === keyboardKeys.Enter && !isEmpty(cleanQuery)) {
        clearTimeout(intentRef?.current);
        trackIntent(() => {
          globalThis.location.href = queryString.stringifyUrl({
            url: RESULT_PAGE_URL,
            query: {
              q: cleanQuery
            }
          });
        });
      }
    },
    [cleanQuery, trackIntent]
  );

  const inputOnKeyUp = useCallback(() => {
    clearTimeout(intentRef?.current);
    intentRef.current = setTimeout(trackIntent, DELAY_SEARCH_INTENT);
  }, [trackIntent]);

  const inputOnFocus = useCallback(
    (openMenu) => () => {
      openMenu();
      setSearchFocus(true);
    },
    [setSearchFocus]
  );

  const inputOnBlur = useCallback(() => {
    setSearchFocus(false);
    setSearchQuery((prev = '') => prev.trim());
  }, [setSearchFocus, setSearchQuery]);

  const onSelect = useCallback(
    (selectedItem) => {
      const optionHref = searchHrefFor(selectedItem);

      trackSearchSuggestAction(ga4Events?.click_search_suggest, {
        query: cleanQuery,
        total: defaultForUndefinedOrNull(suggestionList?.length, 0),
        title: selectedItem?.name,
        url: optionHref,
        listPosition: humanListCount(
          findIndex(suggestionList, (r) => r.id === selectedItem?.id)
        ),
        mode: selectedItem?.search_type,
        trigger: 'popular'
      });

      trackIntent(() => {
        globalThis.location.href = optionHref;
      });
    },
    [cleanQuery, suggestionList, trackIntent]
  );

  useEffect(() => {
    if (overlayRef?.current) {
      const { height } = overlayRef.current.getBoundingClientRect();
      setBoxheight(height);
    }
  }, [isDesktop]);

  useEffect(() => {
    const nextItem = movingList[1];
    if (!isEmpty(nextItem)) {
      setShowPrefix(!!suggestionList.find((i) => i.name === nextItem)?.prefix);
    }
  }, [movingList, suggestionList]);

  // --------------------
  // Mobile UI Test
  // --------------------

  const isMobile = useMediaQuery(mediaQueryPreset.mobile);
  const [cookies] = useCookies([HEADER_INLINE_SEARCH_BAR_KEY]);

  const hasMobileInlineSearch = useMemo(
    () => isMobile && !!cookies?.[HEADER_INLINE_SEARCH_BAR_KEY],
    [cookies, isMobile]
  );

  return (
    <Downshift
      stateReducer={stateReducer}
      itemToString={itemToString}
      onSelect={onSelect}
    >
      {({
        getRootProps,
        getLabelProps,
        getInputProps,
        getMenuProps,
        getItemProps,
        highlightedIndex,
        isOpen,
        openMenu
      }) => (
        <div
          className={classNames('c-nvSearch', {
            'is-search-block': !hasMobileInlineSearch
          })}
        >
          <div
            className={classNames('c-nvSearch__wrapper', {
              'is-focus': isOpen,
              'is-hover': isHover
            })}
          >
            <div
              className="c-nvSearch__field"
              {...getRootProps({}, { suppressRefError: true })}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="c-nvSearch__label hidden" {...getLabelProps()}>
                Search
              </label>
              <div
                ref={overlayRef}
                className="c-nvSearch__overlay u-animate-all "
              >
                <span
                  className="c-nvSearch__icon ic-bef ic-site-search ic-bold u-inline-block u-t-body is-still"
                  aria-hidden
                />
                {showPrefix && (
                  <span
                    className={`c-nvSearch__prefix u-t-nowrap ${
                      searchQuery ? 'hidden' : ''
                    }`}
                  >
                    Search for
                  </span>
                )}
                <span
                  className={`c-nvSearch__cycle ${searchQuery ? 'hidden' : ''}`}
                >
                  <PlaceholderTicker
                    list={displayTerms}
                    height={boxHeight}
                    paused={searchFocus}
                    searchText={cleanQuery}
                    setMovingList={setMovingList}
                  />
                </span>
              </div>
              <input
                className="c-nvSearch__input u-animate-all"
                {...getInputProps({
                  placeholder: isEmpty(displayTerms)
                    ? 'Find everything for your home'
                    : null,
                  onChange: inputOnChange,
                  onKeyDown: inputOnKeyDown,
                  onKeyUp: inputOnKeyUp,
                  onFocus: inputOnFocus(openMenu),
                  onBlur: inputOnBlur,
                  onMouseEnter: inputOnHover(true),
                  onMouseLeave: inputOnHover(false)
                })}
              />
            </div>
            {searchFocus && (
              <div className="c-nvSearch__drop">
                {hasMobileInlineSearch && (
                  <span className="c-nvSearch__drop-shade" />
                )}
                <ul
                  className="c-nvSearch__list u-minied-list"
                  {...getMenuProps()}
                >
                  {suggestionList.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <li
                      {...getItemProps({
                        key: `${item?.id}_${index}`,
                        index,
                        item
                      })}
                      className={classNames('c-nvSearch__item', {
                        'is-active': highlightedIndex === index
                      })}
                    >
                      <a
                        className="c-nvSearch__link u-block u-t-body"
                        href={searchHrefFor(item)}
                        title={item?.name}
                      >
                        <span className="u-t-bold" />
                        {highlighted(item)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </Downshift>
  );
}
