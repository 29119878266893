import { Notifier } from '@airbrake/browser';
import RailsVars from '~/apps/railsVariables.js.erb';

const IGNORE_ERRORS = [
  'Cancel',
  'Network Error',
  'SecurityError',
  'sQuery',
  'ResizeObserver loop limit exceeded',
  'collSwipe',
  'Load failed',
  'Error: API response failed!',
  'Error: Network Error',
  'Failed to fetch',
  'undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
  'M is not a function',
  'Permission denied to access property "message"',
  'Permission denied to access property "noStack"',
  'Permission denied to access property "then"',
  'Permission denied to access property "stack"',
  'Permission denied to access property Symbol.toPrimitive'
];

const airbrake = new Notifier({
  projectId: RailsVars.AIRBRAKE_JS_ID,
  projectKey: RailsVars.AIRBRAKE_JS_KEY,
  environment: process.env.RAILS_ENV,
  keysBlocklist: IGNORE_ERRORS.map((i) => new RegExp(i))
});

airbrake.addFilter((notice) => {
  if (
    process.env.RAILS_ENV === 'development' ||
    notice.errors.some((e) =>
      IGNORE_ERRORS.some((s) => e.message.includes(s) || e.type.includes(s))
    )
  )
    return null;

  return notice;
});

export default airbrake;
