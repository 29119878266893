import airbrake from '~/libs/airbrake';

const amplitudeTrack = (eventName, data) => {
  if (typeof window !== 'object') return;
  if (globalThis?.isMobileApp) return;

  try {
    window?.amplitude?.track(eventName, {
      ...data,
      page_domain:
        eventName === 'page_view' ? window?.location?.hostname : undefined
    });
  } catch (error) {
    airbrake.notify({ error });
  }
};

export { amplitudeTrack };
