import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { useCookies } from 'react-cookie';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import {
  HEADER_INLINE_SEARCH_BAR_KEY,
  ICON_NAV_ROOTS_KEY,
  DEFAULT_NAV_HEIGHT,
  NAV_TOP_THRESHOLD
} from '~/containers/Header/constants';
import { SEARCH_ROUTE } from '~/containers/SearchResult/constants';
import { mediaQueryPreset } from '~/containers/shared/constants';
import atoms from '~/containers/shared/states/atoms';
import headerAtoms from '~/containers/Header/states/atoms';
// import TopBar from '~/components/Header/TopBar';
import MarqueeTicker from '~/components/shared/Marquee/Ticker';
import MarqueeCallouts from '~/components/shared/Marquee/Callouts';
import ControlDesktop from '~/components/Header/ControlRow/Desktop';
import ControlMobile from '~/components/Header/ControlRow/Mobile';
import NavDropdownMenu from '~/components/Header/NavDropdownMenu';
import NavRootsLane from '~/components/Header/NavRootsLane';
import NavSearch from '~/components/Header/NavSearch';
import RegisterModal from '~/components/ModalAuth/Register';
import PasswordResetModal from '~/components/ModalAuth/PasswordReset';
import useMarqueeData from '~/hooks/Header/useMarqueeData';
import useScrollData from '~/hooks/shared/useScrollData';
import useNodeHeight from '~/hooks/shared/useNodeHeight';
import { allowZdWidget, toggleZdWidget } from '~/utils/zendeskControls';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import './view.scss';

const READY_DELAY = 200;

export default function View({ modifierClasses = '', isLoading = false }) {
  const navRef = useRef(null);
  const [ready, setReady] = useState(true);
  const [currentPath, setCurrentPath] = useState('');
  const isMobile = useMediaQuery(mediaQueryPreset.mobile);
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);

  const isClient = useRecoilValue(atoms.isClient);
  const isCollectionPage = useRecoilValue(atoms.isCollectionPage);
  const isProductPage = useRecoilValue(atoms.isProductPage);
  const setNavHeight = useSetRecoilState(atoms.headerHeight);
  const dropdownOpen = useRecoilValue(headerAtoms.dropdownOpen);
  const searchFocus = useRecoilValue(headerAtoms.searchFocus);
  const burgerOpen = useRecoilValue(headerAtoms.burgerOpen);

  const { direction, position } = useScrollData(ready);

  const navHeight = useNodeHeight({
    ref: navRef,
    defaultHeight: DEFAULT_NAV_HEIGHT
  });

  // --------------------
  // Marquee
  // --------------------

  const { interval, ticker, callouts } = useMarqueeData();

  // --------------------
  // Section Visibility
  // --------------------

  const [cookies] = useCookies([
    HEADER_INLINE_SEARCH_BAR_KEY,
    ICON_NAV_ROOTS_KEY
  ]);

  const hasTopBar = useMemo(
    () => isClient && (isDesktop ? !isEmpty(callouts) : !isEmpty(ticker)),
    [callouts, isClient, isDesktop, ticker]
  );

  const showTopBar = useMemo(() => {
    if (isProductPage) return true;
    return position <= NAV_TOP_THRESHOLD / 2;
  }, [isProductPage, position]);

  const showNavDropdownMenu = useMemo(() => {
    if (!isClient || direction === 'up' || isProductPage) return true;
    return position <= NAV_TOP_THRESHOLD * 2 || dropdownOpen;
  }, [direction, dropdownOpen, isClient, isProductPage, position]);

  const hasMobileInlineSearch = useMemo(
    () => isMobile && !!cookies?.[HEADER_INLINE_SEARCH_BAR_KEY],
    [cookies, isMobile]
  );

  const showNavSearchBlock = useMemo(() => {
    if (hasMobileInlineSearch) return false;
    if (!isClient || direction === 'up' || isProductPage) return true;
    return position <= NAV_TOP_THRESHOLD * 1.5 || searchFocus;
  }, [
    direction,
    hasMobileInlineSearch,
    isClient,
    isProductPage,
    position,
    searchFocus
  ]);

  const showRootsLane = useMemo(
    () => isClient && isMobile,
    [isClient, isMobile]
  );

  const compulsoryRootsLane = useMemo(
    () =>
      ['/', SEARCH_ROUTE].includes(currentPath) ||
      isCollectionPage ||
      isProductPage,
    [currentPath, isCollectionPage, isProductPage]
  );

  const iconifiedLinks = useMemo(
    () => isMobile && !!cookies?.[ICON_NAV_ROOTS_KEY],
    [cookies, isMobile]
  );

  const wrapperClasses = useMemo(
    () =>
      classNames('c-head', {
        'is-sticky': !isProductPage,
        'has-opt-roots': showRootsLane && !compulsoryRootsLane,
        [`${modifierClasses}`]: !!modifierClasses
      }),
    [compulsoryRootsLane, isProductPage, modifierClasses, showRootsLane]
  );

  const loadPaddingEms = useMemo(() => {
    // Magic numbers closely match
    // final header heights to minimise
    // 'CLS' SEO penalty
    const topBar = hasTopBar && showTopBar ? 1.07 : 0;
    const control = isDesktop ? 2 : 1.6;
    const menu = isDesktop ? 1.12 : 0;
    const search = isDesktop || hasMobileInlineSearch ? 0 : 1.77;
    const roots = showRootsLane ? 1.2 : 0;
    const rootIcons = iconifiedLinks ? 1 : 0;

    return (topBar + control + menu + search + roots + rootIcons).toFixed(2);
  }, [
    hasMobileInlineSearch,
    hasTopBar,
    iconifiedLinks,
    isDesktop,
    showRootsLane,
    showTopBar
  ]);

  // --------------------
  // Scroll Management
  // --------------------

  useEffect(
    () =>
      setCurrentPath(
        defaultForUndefinedOrNull(globalThis?.location?.pathname, '')
      ),
    []
  );

  useEffect(() => {
    setReady(false);
    setNavHeight(navHeight);

    let pending = null;
    pending = setTimeout(() => setReady(true), READY_DELAY);

    return () => {
      clearTimeout(pending);
      setReady(true);
    };
  }, [
    hasTopBar,
    showTopBar,
    showNavDropdownMenu,
    showNavSearchBlock,
    setNavHeight,
    navHeight
  ]);

  useEffect(() => {
    if (burgerOpen) {
      allowZdWidget(false);
      toggleZdWidget(false, true);
    } else {
      allowZdWidget(true);
      toggleZdWidget(true);
    }
  }, [burgerOpen]);

  if (isLoading)
    return (
      <div
        className="u-b-bg"
        style={{
          textAlign: 'center',
          padding: `${loadPaddingEms}em`
        }}
      />
    );

  return (
    <>
      <RegisterModal />
      <PasswordResetModal />

      <header ref={navRef} className={wrapperClasses}>
        <div className="c-head__wrap">
          {hasTopBar && (
            <div
              className={classNames('c-head__ticker u-animate-all', {
                'is-hidden': !showTopBar
              })}
            >
              {isDesktop ? (
                <MarqueeCallouts list={callouts} />
              ) : (
                <MarqueeTicker
                  list={ticker}
                  duration={interval}
                  // Animation speed needs to be updated together with
                  // ~/components/Header/Marquee/Marquee.scss:16
                  // speed={400}
                />
              )}
            </div>
          )}

          <div className="c-head__container">
            {/* <section className="c-head__top u-b-bg visible-xs">
              <TopBar />
            </section> */}
            <section className="c-head__controls is-notmobile">
              <ControlDesktop />
            </section>
            <section className="c-head__controls is-mobile">
              <ControlMobile />
            </section>
            <section className="c-head__menu">
              {showNavDropdownMenu && <NavDropdownMenu />}
            </section>
            <section
              className={`c-head__search ${
                showNavSearchBlock ? 'has-pad' : ''
              }`}
            >
              {showNavSearchBlock && (
                <div className="c-head__search-wrap">
                  <NavSearch />
                </div>
              )}
            </section>
            {showRootsLane && (
              <section
                className={classNames('c-head__qkRoot', {
                  hidden: !showNavSearchBlock,
                  'is-padded': !iconifiedLinks
                })}
              >
                <NavRootsLane observing={showNavSearchBlock} />
              </section>
            )}
          </div>
        </div>
        <div
          className={classNames('c-head__shade', {
            'is-on': dropdownOpen
          })}
        />
      </header>
    </>
  );
}
