import numeral from 'numeral';
import { DateTime, Interval } from 'luxon';
import { isFinite, isInteger, isEmpty, isFunction, merge } from 'lodash';
import { buildURL } from 'react-imgix';
import queryString from 'query-string-for-all';
import i18n from '~/i18n';

import { navigateToLogin } from '~/utils/rnWebView';
import { imgixDomain } from '~/utils/imgix';

const isSingapore = process.env.STORE_COUNTRY === 'Singapore';

const getCustomerSessionId = () => {
  const sessionId = new URLSearchParams(window.location.search).get(
    'session_id'
  );

  if (globalThis.isMobileApp && sessionId && sessionId.trim().length) {
    return sessionId;
  }
  return globalThis.CUSTOMER_SESSION_ID;
};

const defaultForUndefinedOrNull = (incomingValue, defaultValue) =>
  incomingValue ?? defaultValue;

const defaultForFalsy = (incomingValue, defaultValue) =>
  incomingValue || defaultValue;

const isSlowNetworkConnection = () => {
  let isLow = false;

  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;

  if (connection) {
    const speed =
      connection.downlink || connection.downlinkMax || connection.bandwidth;
    isLow = speed <= 0.8;
  }

  return isLow;
};

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const removeHtmlTag = (htmlText) => {
  return htmlText
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/,/g, ', ')
    .replace(/\./g, '. ')
    .replace(/\s+/g, ' ')
    .trim();
};

const profileDisplayName = (
  user = {},
  customName = null,
  defaultToId = true,
  placeholder = 'User'
) => {
  const preferName = defaultForUndefinedOrNull(customName, user?.nickname);
  if (!isEmpty(preferName)) return preferName;
  return defaultToId && !isEmpty(user?.auth_user_id)
    ? user.auth_user_id
    : placeholder;
};

const userDataDisplayPic = (user, prop = 'image') => {
  if (user?.[prop]) {
    return user?.[prop];
  }
  if (user?.facebook_id) {
    const path = `https://graph.facebook.com/${user.facebook_id}/picture`;

    return queryString.stringifyUrl({
      url: path,
      query: {
        type: 'large'
      }
    });
  }
  return null;
};

const urlMergeParams = ({
  rawUrl = '',
  params = {},
  overrideHash = undefined,
  asJson = false
} = {}) => {
  if (isEmpty(rawUrl)) return asJson ? {} : null;

  const {
    url = '',
    query = {},
    fragmentIdentifier: rawHash = null
  } = queryString.parseUrl(rawUrl, { parseFragmentIdentifier: true });

  const isImgix = rawUrl.indexOf(imgixDomain) > -1;
  const finalParams = merge({}, params, query);
  const finalHash = defaultForUndefinedOrNull(overrideHash, rawHash);
  const finalData = {
    url,
    query: finalParams,
    fragmentIdentifier: finalHash,
    isImgix
  };
  const finalString = isImgix
    ? buildURL(url, finalParams)
    : queryString.stringifyUrl(finalData);

  return asJson ? finalData : finalString;
};

const titleize = (string) =>
  string.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const humanListCount = (val, offset = 1) =>
  isInteger(val) ? val + offset : val;

const formatPoints = (val, unit = 'unit') =>
  isInteger(val)
    ? `${numeral(val).format('0,0')} ${i18n.t(`viewaccounts:point.${unit}`, {
        count: val
      })}`
    : null;

const formatPrice = (price, separator = false, placeholder = '-') =>
  isFinite(parseFloat(price))
    ? numeral(price).format(!separator ? '0[.]00' : '0,0[.]00')
    : placeholder;

const formatTotalSold = (value, abbrThreshold = 1000) => {
  if (!isInteger(value) || value <= 0) return '-';
  if (value < abbrThreshold) return value;
  const roundThousand = value % abbrThreshold === 0;

  return numeral(value).format(roundThousand ? '0a' : '0[.]0a');
};

const roundNum = (value, places = 0) => {
  if (!isFinite(value) || !isInteger(places)) return null;

  const positivePlace = places > -1;
  const incr = `e+${Math.abs(places)}`;
  const decr = `e-${Math.abs(places)}`;

  return positivePlace
    ? +(Math.round(value + incr) + decr)
    : +(Math.round(value + decr) + incr);
};

const slugify = (topic) => {
  const SLUG_STEPS = [
    { match: '\\s+', flags: 'g', sub: '-' },
    { match: '[^\\w\\-]+', flags: 'g', sub: '' },
    { match: '\\-\\-+', flags: 'g', sub: '-' },
    { match: '^-+', flags: '', sub: '' },
    { match: '-+$', flags: '', sub: '' }
  ];

  const lowerAscii = topic.toString().normalize('NFD').toLowerCase();
  return SLUG_STEPS.reduce(
    (res, step) => res.replace(new RegExp(step.match, step.flags), step.sub),
    lowerAscii
  );
};

const handleFromSlug = (slug) => slug.replace(/[/?#].*/, '');

const formatBytes = (bytes, places = 0, base = 1000) => {
  if (bytes === 0) return { value: 0, unit: 'Bytes' };
  const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
  const i = Math.floor(Math.log(bytes) / Math.log(base));
  return { value: roundNum(bytes / base ** i, places), unit: sizes[i] };
};

// FIXME: when we migrate the login modal to React,
// content - string:
// general || referral || wishlist || notify || rewards || reviews ||
// likes || commentlikes || saves || postjoin || comments || follows

// eslint-disable-next-line no-underscore-dangle
const _mustSetRedirect = (content) =>
  !!content && 'credits referral wishlist'.includes(content.toLowerCase());

const loadOutsideSignupModal = ({
  content = 'general',
  mode = 'signup',
  location = 'unknown_loadOutsideSignupModal',
  postId,
  itemId
} = {}) => {
  if (typeof window !== 'object') return;

  if (globalThis?.isMobileApp) {
    navigateToLogin();
  } else {
    globalThis.HV.ShowAuthModal.setAuthMode(mode);
    if (_mustSetRedirect()) {
      globalThis.HV.ShowAuthModal.setRedirectFlag(content);
    }
    globalThis.HV.ShowAuthModal.showContents(mode, content);
    globalThis.HV.AUTH_LOCATION = location;
    if (!isEmpty(postId) && isEmpty(itemId)) {
      globalThis.HV.AUTH_TRIGGER_TYPE = 'post_picture';
      globalThis.HV.AUTH_TRIGGER_ID = postId;
    }
    if (!isEmpty(itemId) && isEmpty(postId)) {
      globalThis.HV.AUTH_TRIGGER_TYPE = 'hv_product';
      globalThis.HV.AUTH_TRIGGER_ID = itemId;
    }
    $('#auth_modal').modal('show');
  }
};

const countWords = (content) => {
  let count = 0;
  content.forEach((value) => {
    let s = value.children[0].text;
    if (s.length !== 0 && s.match(/\b[-?(\w+)?]+\b/gi)) {
      s = s.replace(/(^\s*)|(\s*$)/gi, '');
      s = s.replace(/[ ]{2,}/gi, ' ');
      s = s.replace(/\n /, '\n');
      count += s.split(' ').length;
    }
  });
  return count;
};

const priorityLocales = ({ data = [], priority = [], byProp = 'alpha2' }) => ({
  pick: data.filter((loc) => priority.includes(loc[byProp])),
  rest: data.filter((loc) => !priority.includes(loc[byProp]))
});

const processedLocales = ({ data = [], transform = null, sortBy = 'name' }) => {
  const cleanData = data.filter((loc) => loc?.status === 'assigned');
  const shapedData = isFunction(transform)
    ? cleanData.map(transform)
    : cleanData;
  return isEmpty(sortBy)
    ? shapedData
    : shapedData.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
};

const getFormattedBirthDay = ({ day, month, year }) =>
  `${year}-${month}-${day}`;

const getDateObj = (date) =>
  date ? DateTime.fromISO(date) : { day: '', month: '', year: '' };

const formattedDate = (date, format = 'd MMM y') =>
  DateTime.fromISO(date).toFormat(format);

const canonicalDurations = [
  // { key: 'years', label: 'y' },
  // { key: 'months', label: 'mo' },
  { key: 'weeks', label: 'w' },
  { key: 'days', label: 'd' },
  { key: 'hours', label: 'h' },
  { key: 'minutes', label: 'm', lowest: 1, lowestLabel: 'Just now' }
];

const abbrvTimestamp = (isoDateTime) => {
  let result = { value: null, unit: null };
  if (!DateTime.fromISO(isoDateTime).isValid) return result;

  const steps = canonicalDurations.map((dura) => dura?.key);
  const durationObject = DateTime.now()
    .diff(DateTime.fromISO(isoDateTime), steps)
    .toObject();

  steps.some((step) => {
    if (durationObject?.[step] <= 0) return false;
    const dura = canonicalDurations.find(({ key }) => key === step);
    const tooLow = !!dura?.lowest && durationObject[step] < dura?.lowest;
    result = {
      ...(tooLow && { label: dura?.lowestLabel }),
      value: roundNum(durationObject[step]),
      unit: dura?.label
    };
    return true;
  });
  return result;
};

const relativeTimestamp = (datetime) => {
  const currently = DateTime.now();
  const reference = DateTime.fromISO(datetime);
  const threshold = currently.minus({ days: 14 }).startOf('day');

  if (reference > threshold) return reference.toRelative();
  const format = reference.hasSame(currently, 'year') ? 'd MMMM' : 'd MMMM y';
  return reference.toFormat(format);
};

const currentTimeVisible = (start, end) => {
  // Handle empty
  if (isEmpty(start) && isEmpty(end)) return true;

  const startDt = DateTime.fromISO(start);
  const endDt = DateTime.fromISO(end);

  // Handle failure
  if (!startDt.isValid && !endDt.isValid) return false;

  // Handle half ranges
  if (startDt.isValid && !endDt.isValid) return startDt <= DateTime.now();
  if (!startDt.isValid && endDt.isValid) return endDt > DateTime.now();

  // Handle pair ranges
  return Interval.fromDateTimes(startDt, endDt).contains(DateTime.now());
};

const currentTimePrefer = (listData = [], pickFrom = null, asc = true) => {
  if (!['start', 'end'].includes(pickFrom)) return listData?.[0];

  const { invalid, unbounded, startBounded, endBounded, bounded } =
    listData.reduce(
      (results, item) => {
        // Handle empty
        if (isEmpty(item?.start) && isEmpty(item?.end)) {
          results.unbounded.push(item);
          return results;
        }

        const startDt = DateTime.fromISO(item?.start);
        const endDt = DateTime.fromISO(item?.end);

        // Handle failure
        if (!startDt.isValid && !endDt.isValid) {
          results.invalid.push(item);
          return results;
        }

        // Handle half ranges
        if (startDt.isValid && !endDt.isValid) {
          results.startBounded.push(item);
          return results;
        }
        if (!startDt.isValid && endDt.isValid) {
          results.endBounded.push(item);
          return results;
        }

        results.bounded.push(item);
        return results;
      },
      {
        invalid: [],
        unbounded: [],
        startBounded: [],
        endBounded: [],
        bounded: []
      }
    );

  const fromEnd = pickFrom === 'end';
  const mod = (fromEnd ? -1 : 1) * (asc ? 1 : -1);
  const ts = (obj) => DateTime.fromISO(obj?.[pickFrom]).ts;

  const startItems = [...bounded, ...startBounded];
  const endItems = [...bounded, ...endBounded];
  const openItems = [...unbounded, ...invalid];

  const preferOpen = fromEnd
    ? openItems.slice().reverse()?.[0]
    : openItems?.[0];
  const preferBound = bounded.sort((a, b) => ts(a) * mod - ts(b) * mod)?.[0];
  const preferStart = fromEnd
    ? preferBound
    : startItems.sort((a, b) => ts(a) * mod - ts(b) * mod)?.[0];
  const preferEnd = fromEnd
    ? endItems.sort((a, b) => ts(a) * mod - ts(b) * mod)?.[0]
    : preferBound;

  return (
    fromEnd
      ? [preferEnd, preferStart, preferOpen]
      : [preferStart, preferEnd, preferOpen]
  ).filter((i) => !isEmpty(i))?.[0];
};

const removeEmptyValues = (obj) =>
  Object.entries(obj).reduce(
    (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
    {}
  );

export {
  isSingapore,
  isSlowNetworkConnection,
  retry,
  removeHtmlTag,
  profileDisplayName,
  userDataDisplayPic,
  urlMergeParams,
  titleize,
  humanListCount,
  formatPoints,
  formatPrice,
  formatTotalSold,
  roundNum,
  slugify,
  handleFromSlug,
  loadOutsideSignupModal,
  defaultForUndefinedOrNull,
  defaultForFalsy,
  formatBytes,
  countWords,
  priorityLocales,
  processedLocales,
  getFormattedBirthDay,
  getDateObj,
  formattedDate,
  abbrvTimestamp,
  relativeTimestamp,
  currentTimeVisible,
  currentTimePrefer,
  removeEmptyValues,
  getCustomerSessionId
};
